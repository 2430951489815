@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Marine-Rounded', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.25;
    background-color: #27b0d4;
    color: #4a4a4a;
  }

  svg {
    color: #fff;
  }

  a {
    color: #f26521;
  }

  img {
    max-width: 100%;
  }

  p {
    margin: 0 0 1.5rem;
    padding: 0;
  }

  ul {
    margin: 0 0 1.5rem;
  }

  input {
    font-family: inherit;
  }

  button {
    padding: 0;
    border: 0;
    outline: 0;
    border-radius: 0;
    appearance: none;
    font-family: inherit;
  }

  ::placeholder {
    color: #27b0d4;
    opacity: 0.6;
    font-style: italic;
  }

  /* Headlines */

  h1,
  h2 {
    font-weight: 400;
    font-style: italic;
    margin-top: 0;
  }

  h1 {
    font-size: 2.25em;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5rem;
  }

  p,
  li {
    font-size: 1.25em;
  }
}

@layer components {
  .h2 {
    font-weight: 400;
    font-style: italic;
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }

  .front-page-headline {
    @apply m-0 italic font-normal text-center text-[2rem] md:text-4xl;
  }
}

/* Helpers */

.text-inherit p {
  font-size: inherit;
}

.text-blue p,
.text-blue h2 {
  color: #27b0d4;
}

/* used by WordPress */
.table-of-contents {
  padding: 3% 3% 3% 3%;
  font-size: 1rem;
  width: 70%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  margin: auto;
}
