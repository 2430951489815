.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.fadeMount-enter {
  opacity: 0;
}
.fadeMount-enter-active {
  opacity: 1;
  transition: opacity .3s;
}
.fadeMount-exit {
  opacity: 1;
}
.fadeMount-exit-active {
  opacity: 0;
  transition: opacity .3s;
}