.rdrDateDisplay .rdrDateInput.rdrDateDisplayItem:last-child {
  display: none !important;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
} 

.rdrDayEndOfMonth .rdrInRange::after,
.rdrDayEndOfMonth .rdrStartEdge::after {
  content: "";
  position: absolute;
  top: 2px;
  right: -3px;
  bottom: 2px;
  width: 2px;
  background-color: rgba(39, 176, 212, 0.8);
}

.rdrDayEndOfMonth .rdrInRange::before,
.rdrDayEndOfMonth .rdrStartEdge::before {
  content: "";
  position: absolute;
  top: 4px;
  right: -6px;
  bottom: 4px;
  width: 2px;
  background-color: rgba(39, 176, 212, 0.5);
}

.rdrDayStartOfMonth .rdrInRange, 
.rdrDayStartOfMonth .rdrEndEdge {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rdrDayStartOfMonth .rdrInRange::after, 
.rdrDayStartOfMonth .rdrEndEdge::after {
  content: "";
  position: absolute;
  top: 2px;
  left: -3px;
  bottom: 2px;
  width: 2px;
  background-color: rgba(39, 176, 212, 0.8);
}

.rdrDayStartOfMonth .rdrInRange::before,
.rdrDayStartOfMonth .rdrEndEdge::before {
  content: "";
  position: absolute;
  top: 4px;
  left: -6px;
  bottom: 4px;
  width: 2px;
  background-color: rgba(39, 176, 212, 0.5);
}