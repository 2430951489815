.slick-arrow::before {
  color: #f26521;
  font-size: 40px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.slick-arrow:hover::before {
  opacity: 0.9;
}

.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
}
.slick-prev {
  left: -50px;
}
.slick-next {
  right: -50px;
}
.slick-disabled {
  display: none !important;
}
.slick-slide video {
  width: 90%;
  margin: 0 5%;
}

.slick-slide h3 {
  width: 90%;
  margin: 10px 5%;
}

.slick-slider {
  margin: 0 50px;
}
