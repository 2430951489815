/**
* @license
* MyFonts Webfont Build ID 336191
* 
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are 
* explicitly restricted from using the Licensed Webfonts(s).
* 
* You may obtain a valid license from one of MyFonts official sites.
* http://www.fonts.com
* http://www.myfonts.com
* http://www.linotype.com
*
*/

@font-face {
    font-family: 'Marine-Rounded';
    font-weight: bold;
    font-style: italic;
    src: url('./Marine-Rounded-Bold-Italic/font.woff2') format('woff2'), url('./Marine-Rounded-Bold-Italic/font.woff') format('woff');
}

@font-face {
    font-family: 'Marine-Rounded';
    font-weight: bold;
    font-style: normal;
    src: url('./Marine-Rounded-Bold/font.woff2') format('woff2'), url('./Marine-Rounded-Bold/font.woff') format('woff');
}

@font-face {
    font-family: 'Marine-Rounded';
    font-weight: normal;
    font-style: italic;
    src: url('./Marine-Rounded-Regular-Italic/font.woff2') format('woff2'), url('./Marine-Rounded-Regular-Italic/font.woff') format('woff');
}

@font-face {
    font-family: 'Marine-Rounded';
    font-weight: normal;
    font-style: normal;
    src: url('./Marine-Rounded-Regular/font.woff2') format('woff2'), url('./Marine-Rounded-Regular/font.woff') format('woff');
}